import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import BannerShape from "../assets/images/shapes/banner-shape-1-1.png";
import BannerBG from "../assets/images/resources/banner-image-1-1.jpg";
import BannerMoc from "../assets/images/resources/banner-moc-1-1.png";
import qrCodeImage from '../assets/images/qr.jpg';
const Banner = () => {
  const [open, setOpen] = useState({
    isOpen: false
  });
  const openModal = () => {
    setOpen({
      isOpen: true
    });
  };

  return (
    <section className="banner-one" id="home">
      <img
        src={BannerShape}
        className="banner-one__bg-shape-1"
        alt="awesome post"
      />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${BannerBG})` }}
      ></div>
      <div className="container">
        {/* <ModalVideo
          channel="youtube"
          isOpen={open.isOpen}
          videoId="Kl5B6MBAntI"
          onClose={() => setOpen({ isOpen: false })}
        />
        <div
          onClick={openModal}
          onKeyDown={openModal}
          role="button"
          tabIndex="0"
          className="banner-one__video video-popup"
        >
          <i className="fa fa-play"></i>
        </div> */}
        <div className="banner-one__moc">
          <img
            src={BannerMoc}
            className="wow fadeInUp"
            data-wow-duration="1500ms"
            alt="awesome post"
          />
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-one__content">
              {/* <form
                className="banner-one__mc-form mc-form"
                data-url="MAILCHIMP__POPUP__FORM__URL"
              >
                <input type="text" name="email" placeholder="Email address" />
                <button type="submit" className="thm-btn banner-one__mc-btn">
                  <span>Free Trial</span>
                </button>
              </form> */}
              {/* <div className="mc-form__response"></div> */}
              <h3>
              Tìm Kiếm Tình Yêu Chân Thực <br />Cùng Ứng Dụng Hẹn Hò "iudi"
                
              </h3>
              <p>
              Chào mừng bạn đến với iudi, ứng dụng hẹn hò duy nhất tại thời điểm này  ở <br /> Việt Nam hoạt động trên nền tảng Zalo.  <br /> Được phát triển và điều hành bởi Nối Group, thương hiệu đã hoạt động hơn<br /> 10 năm trong lĩnh vực kết nối  những  người độc thân.            
               <br />Đây không chỉ là một app hẹn hò, mà là nơi kết nối những tâm hồn <br /> đồng điệu với sứ mệnh xây dựng mối quan hệ bền vững và ý nghĩa. {" "}
                
              </p>
              {/* <a href="#none" className="thm-btn banner-one__btn">
                <span>Discover More</span>
              </a> */}
            <div style={{ display: 'flex', justifyContent: 'center', listStyleType: 'none', padding: 0 }}>
  <div>
    <img
      src={qrCodeImage}
      alt="QR Code"
      style={{ width: '300px', height: '300px', objectFit: 'contain' }}
    />
  </div>
</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
