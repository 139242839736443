import React from "react";
import BlockTitle from "./BlockTitle";

import CTAImage1 from "../assets/images/resources/cta-2-moc-11.png";
import CTAImage2 from "../assets/images/resources/cta-2-moc-2.png";
import CTAImage3 from "../assets/images/resources/cta-2-moc-3.png";
import talk1 from "../assets/images/resources/talk1.png";
import search from "../assets/images/resources/search.png";
const CTATwo = () => {
  return (
    <section className="cta-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <BlockTitle
                textAlign="left"
                paraText="Tính năng nổi bật"
                titleText={`Với "iudi," bạn không chỉ tìm thấy tình yêu, mà còn tìm thấy sự an toàn và bảo mật riêng tư.`}
              />
              <div className="cta-two__icon-wrap">
                <div className="cta-two__icon-single">
                  <div className="cta-two__icon">
                    {/* <i className="apton-icon-app-development"></i> */}
                    <img
  src={talk1}
  className="wow fadeInUp1"
  data-wow-duration="1500ms"
  alt="awesome post"
  style={{ width: '70%', maxWidth: '300px', height: 'auto' }}
/>
                  </div>
                  <h3>
                  Chát qua Zalo nhưng chưa biết số điện thoại của nhau
                  </h3>
                </div>
                <div className="cta-two__icon-single">
                  <div className="cta-two__icon">
                    {/* <i className="apton-icon-computer-graphic1"></i> */}
                    <img
  src={search}
  className="wow fadeInUp"
  data-wow-duration="1500ms"
  alt="awesome post"
  style={{ width: '70%', maxWidth: '300px', height: 'auto' }}
/>
                  </div>
                  <h3>
                  Tìm kiếm đúng đối tượng bạn mong muốn
                  </h3>
                </div>
              </div>
              <div className="cta-two__text">
                <p>
                -	Tại iudi, chúng tôi tin rằng ai cũng xứng đáng có được một tình yêu chân thực và mối quan hệ vững chắc. Đừng bỏ lỡ cơ hội trải nghiệm những cuộc trò chuyện chân thành và những buổi hẹn gặp đầy thú vị.
                </p>
              </div>
              {/* <a href="#none" className="thm-btn cta-two__btn">
                <span>Discover More</span>
              </a> */}
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="cta-two__images d-flex justify-content-end align-items-end flex-column">
            <img
  src={CTAImage1}
  className="wow fadeInUp"
  data-wow-duration="1500ms"
  alt="awesome post"
  style={{ width: '100%', maxWidth: '500px', height: 'auto' }}
/>
              {/* <img
                src={CTAImage2}
                className="wow fadeInUp"
                data-wow-duration="1500ms"
                alt="awesome post"
              />
              <img
                src={CTAImage3}
                className="wow fadeInUp"
                data-wow-duration="1500ms"
                alt="awesome post"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTATwo;
