import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Pagination, Thumbs, Controller } from "swiper";
import Swiper from "react-id-swiper";
import BlockTitle from "./BlockTitle";

import TestiQoute from "../assets/images/shapes/testi-qoute-1-1.png";

import TestiImageOne from "../assets/images/resources/testi-1-1.jpg";
import TestiImageTwo from "../assets/images/resources/testi-1-2.jpg";
import TestiImageThree from "../assets/images/resources/testi-1-3.jpg";
import TestiImage4 from "../assets/images/resources/testi-1-4.jpg";
import TestiImage5 from "../assets/images/resources/testi-1-5.jpg";
import TestiImage6 from "../assets/images/resources/testi-1-6.jpg";

SwiperCore.use([Pagination, Thumbs, Controller]);

const Testimonials = (props) => {
  const paramsTwo = {
    observer: true,
    observeParents: true,
    speed: 1400,
    mousewheel: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".testimonials-one__pagination-wrap .swiper-pagination",
      clickable: true
    }
  };

  const paramsOne = {
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "fade",
    speed: 1400,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };

  return (
    <section className="testimonials-one">
      <Container>
        <Row>
          <Col xl={6}>
            <div className="testimonials-one__thumb-carousel-wrap">
              <div className="testimonials-one__icon">
                <div className="testimonials-one__icon-inner">
                  <img src={TestiQoute} alt="awesome post" />
                </div>
              </div>
              <div className="testimonials-one__thumb-carousel">
                <Swiper {...paramsOne}>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImageOne} alt="awesome post" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImageTwo} alt="awesome post" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImageThree} alt="awesome post" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImage4} alt="awesome post" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImage5} alt="awesome post" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__image">
                      <img src={TestiImage6} alt="awesome post" />
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </Col>
          <Col xl={6} className="d-flex">
            <div className="my-auto">
              <BlockTitle
                textAlign="left"
                paraText="Chia sẻ"
                titleText={`Các cặp đôi Nối Thành Công`}
              />
              <div className="testimonials-one__carousel">
                <Swiper {...paramsTwo}>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
                      "Trước khi dùng iudi, mình không nghĩ sẽ tìm được một người hiểu và đồng cảm với mình. Nhưng giờ đây, mình đã tìm được một nửa thật sự, một người luôn bên cạnh sẻ chia mọi niềm vui, nỗi buồn. Cảm ơn iudi đã giúp mình mở ra cánh cửa tình yêu!" 🌹💑                      </p>

                      <h3 className="testimonials-one__title">Phạm Tuyên & Nguyễn Vân</h3>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
                      "Mình từng nghĩ ứng dụng hẹn hò chỉ là nơi vui chơi, nhưng iudi hoàn toàn khác. Đây là cộng đồng kết nối an toàn, văn minh, giúp mình tìm được một người bạn đời thực sự. Những cuộc trò chuyện chân thành, những lần gặp gỡ đáng nhớ, tất cả đều bắt đầu từ iudi." 🌟                      </p>

                      <h3 className="testimonials-one__title">Đức Tuân & Hải Yến</h3>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
                      "Từ lúc sử dụng iudi, mình nhận ra việc hẹn hò không còn khó khăn và nhàm chán như trước nữa. Chỉ trong vài bước đơn giản, mình đã kết nối với nhiều người thú vị. Và rồi, người đặc biệt đã xuất hiện – người đã làm cuộc sống mình thay đổi theo cách tuyệt vời nhất." 💕                      </p>

                      <h3 className="testimonials-one__title">Văn Thủy & Tú Anh</h3>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
                      "Mình đã từng hoài nghi về ứng dụng hẹn hò, nhưng iudi đã thay đổi quan điểm của mình. Các kết nối trên đây đều chân thành, nghiêm túc. Và chính nhờ sự chân thành đó, mình đã tìm được người đặc biệt, người khiến trái tim mình đập rộn ràng trở lại." 💞🌈         </p>            
                        <h3 className="testimonials-one__title"> Thanh Lâm & Ngọc Anh</h3>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
"Mình đã tải iudi về với chút hy vọng và tò mò. Không ngờ rằng, hành trình thoát 'ế' lại dễ dàng và thú vị đến vậy. Những cuộc trò chuyện vui vẻ, sự quan tâm chân thành đã dẫn đến một mối quan hệ tuyệt đẹp mà mình luôn ao ước. Giờ đây, mình có thể tự hào nói rằng: iudi thật sự đã thay đổi cuộc đời mình!" 🌻🌟</p>
                      <h3 className="testimonials-one__title">Nguyễn Bá T & Võ Thu H</h3>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonials-one__single">
                      <p className="testimonials-one__text">
                      "Từ lúc sử dụng iudi, mình nhận ra việc hẹn hò không còn khó khăn và nhàm chán như trước nữa. Chỉ trong vài bước đơn giản, mình đã kết nối với nhiều người thú vị. Và rồi, người đặc biệt đã xuất hiện – người đã làm cuộc sống mình thay đổi theo cách tuyệt vời nhất." 💕                      </p>

                      <h3 className="testimonials-one__title">Trung Quân & Bảo Chiêu</h3>
                    </div>
                  </div>
                </Swiper>
                <div className="testimonials-one__pagination-wrap">
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
