import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import faqBG from "../assets/images/shapes/faq-bg-1-1.png";

const FAQ = (props) => {
  return (
    <section className="faq-one">
      <img src={faqBG} className="faq-one__bg-shape-1" alt="awesome post" />
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Câu hỏi thường gặp"
          titleText={`Vì Sao Nên Chọn "iudi"?`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="Bảo mật và an toàn tuyệt đối với nền tảng Zalo"
            content="• iudi tận dụng sức mạnh bảo mật từ Zalo, giúp bạn đăng ký và tham gia với tài khoản Zalo cá nhân. Điều này không chỉ giúp bảo vệ quyền riêng tư của bạn mà còn giúp cập nhật các kết nối và tương tác một cách nhanh chóng, tiện lợi."
            status={true}
          />
          <AccordionItem
            title="100% thành viên thật - Tài khoản thật tại app"
            content="• Chúng tôi cam kết 100% thành viên thật. Mỗi người tham gia đều được xác thực và xác minh giấy tờ tùy thân, mang đến sự tin cậy tuyệt đối trong từng kết nối. Không còn lo lắng về các tài khoản ảo hay thiếu trung thực."
            status={false}
          />
        <AccordionItem
  title="Tính năng bảo mật đỉnh cao"
  content={
    <>
      <p>• Thông tin cá nhân như địa chỉ, số điện thoại, CCCD hay nick Facebook của bạn sẽ được bảo mật tuyệt đối. Những hình ảnh cá nhân chỉ hiển thị cho các thành viên đã đăng ký và được kiểm duyệt bởi Ban Quản Trị.</p>
      <br/>
      <p>• Bạn hoàn toàn kiểm soát được ai có quyền xem thông tin và hình ảnh của mình.</p>
    </>
  }
  status={false}
/>
          <AccordionItem
            title="Hỗ trợ 24/7"
            content="• Đội ngũ nhân viên hỗ trợ online 24/7, giải đáp mọi thắc mắc và xử lý các vấn đề phát sinh một cách kịp thời. Bạn có thể yên tâm trải nghiệm và tìm kiếm tình yêu mà không phải lo lắng về bất kỳ vấn đề kỹ thuật nào."
            status={false}
          />
         
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
