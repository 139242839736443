// import React from "react";
// import BlockTitle from "./BlockTitle";

// import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
// import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
// import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";

// const CTAOne = () => {
//   return (
//     <section className="cta-one">
//       <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
//       <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
//       <div className="container">
//         <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
//           <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
//         </div>
//         <div className="row justify-content-end">
//           <div className="col-lg-6">
//             <div className="cta-one__content">
//               <BlockTitle
//                 textAlign="left"
//                 paraText="Giới thiệu"
//                 titleText={`Ông Điệp Hoàng \n - CEO của Nối Group`}
//               />
//               <div className="cta-one__text">
//                 <p>
//                 Sứ mệnh của chúng tôi là xây dựng một cộng đồng kết nối những người độc thân với sự nghiêm túc, văn minh, an toàn và hiệu quả, giúp họ tìm thấy tình yêu chân thành và hạnh phúc lâu bền 
//                 </p>
//               </div>
//               <ul className="list-unstyled cta-one__list">
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Bảo mật và an toàn tuyệt đối với nền tảng Zalo.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   100% thành viên thật - Tài khoản thật tại app.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Tính năng bảo mật đỉnh cao.
//                 </li>
//                 <li>
//                   <i className="fa fa-check-circle"></i>
//                   Hỗ trợ 24/7.
//                 </li>
//               </ul>
//               <a href="#none" className="thm-btn cta-one__btn">
//                 <span>Khám phá thêm</span>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CTAOne;
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import BlockTitle from "./BlockTitle";

import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
import CtaMoc1 from "../assets/images/resources/cta-1-moc-1.png";

const CTAOne = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="cta-one" id="gioithieu">
      <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
      <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
      <div className="container">
        <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
          <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="Giới thiệu"
                titleText={`Mr. Điệp Hoàng - CEO của Nối Group`}
              />
              <div className="cta-one__text">
                <p>
                  Sứ mệnh của chúng tôi là xây dựng một cộng đồng kết nối những người độc thân với sự nghiêm túc, văn minh, an toàn và hiệu quả, giúp họ tìm thấy tình yêu chân thành và hạnh phúc lâu bền.
                </p>
              </div>
              <ul className="list-unstyled cta-one__list">
                <li>
                  <i className="fa fa-check-circle"></i> Bảo mật và an toàn  với nền tảng Zalo.
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> 100% thành viên thật - Tài khoản thật tại app.
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Tính năng bảo mật đỉnh cao.
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Hỗ trợ 24/7.
                </li>
              </ul>
              <Button variant="primary" onClick={handleShow} className="thm-btn cta-one__btn">
               <span>Khám phá thêm</span> 
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Giới thiệu iudi.app</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
         
          <p><strong>Tìm Kiếm Tình Yêu Chân Thực Cùng Ứng Dụng Hẹn Hò "iudi"</strong></p>
          <p>HÃY YÊU NHAU ĐI – Một Cộng Đồng Kết Nối An Toàn, Văn Minh và Nghiêm Túc</p>
          <p>
            Chào mừng bạn đến với iudi, ứng dụng hẹn hò duy nhất tại thời điểm này ở Việt Nam hoạt động trên nền tảng Zalo. 
            Đây không chỉ là một app hẹn hò, mà là nơi kết nối những tâm hồn đồng điệu với sứ mệnh xây dựng mối quan hệ bền vững và ý nghĩa.
          </p>
          <p>
            Với "iudi," bạn không chỉ tìm thấy tình yêu, mà còn tìm thấy sự an toàn và bảo mật tuyệt đối.
          </p>

          <h5>Vì Sao Nên Chọn "iudi"?</h5>
          <ol>
            <li><strong>Bảo mật và an toàn tuyệt đối với nền tảng Zalo:</strong> 
              iudi tận dụng sức mạnh bảo mật từ Zalo, giúp bạn đăng ký và tham gia với tài khoản Zalo cá nhân. Điều này không chỉ giúp bảo vệ quyền riêng tư của bạn mà còn giúp cập nhật các kết nối và tương tác một cách nhanh chóng, tiện lợi.
            </li>
            <li><strong>100% thành viên thật - Tài khoản thật tại app:</strong> 
              Chúng tôi cam kết 100% thành viên thật. Mỗi người tham gia đều được xác thực và xác minh giấy tờ tùy thân, mang đến sự tin cậy tuyệt đối trong từng kết nối. Không còn lo lắng về các tài khoản ảo hay thiếu trung thực.
            </li>
            <li><strong>Tính năng bảo mật đỉnh cao:</strong> 
              Thông tin cá nhân như địa chỉ, số điện thoại, CCCD hay nick Facebook của bạn sẽ được bảo mật tuyệt đối. Những hình ảnh cá nhân chỉ hiển thị cho các thành viên đã đăng ký và được kiểm duyệt bởi Ban Quản Trị.
            </li>
            <li><strong>Hỗ trợ 24/7:</strong> 
              Đội ngũ nhân viên hỗ trợ online 24/7, giải đáp mọi thắc mắc và xử lý các vấn đề phát sinh một cách kịp thời. Bạn có thể yên tâm trải nghiệm và tìm kiếm tình yêu mà không phải lo lắng về bất kỳ vấn đề kỹ thuật nào.
            </li>
          </ol>

          <h5>Một Cộng Đồng Văn Minh, Nghiêm Túc và Hiệu Quả</h5>
          <p>
            iudi được điều hành bởi Nối Group, đơn vị đã có nhiều năm kinh nghiệm trong việc xây dựng cộng đồng kết nối độc thân văn minh và nghiêm túc tại Việt Nam. 
            Với mong muốn mang lại một môi trường hẹn hò an toàn và hiệu quả, chúng tôi không ngừng nỗ lực xây dựng những tính năng hỗ trợ kết nối tốt nhất và đảm bảo sự minh bạch trong mọi tương tác.
          </p>

          <h5>Tham Gia "iudi" – Trải Nghiệm Hẹn Hò Thực Sự</h5>
          <p>
            Tại iudi, chúng tôi tin rằng ai cũng xứng đáng có được một tình yêu chân thực và mối quan hệ vững chắc. 
            Đừng bỏ lỡ cơ hội trải nghiệm những cuộc trò chuyện chân thành và những buổi hẹn gặp đầy thú vị. Hãy để iu di trở thành cầu nối giúp bạn tìm thấy tình yêu đích thực. Tham gia ngay hôm nay tại www.iudi.app.
          </p>

          <h5>VỀ CHÚNG TÔI</h5>
          <p>
            • Công Ty TNHH Nối Group<br />
            • MST: 0318379051<br />
            • Tầng 08, Toà nhà Pearl Plaza, Số 561A Điện Biên Phủ, P.25, Q.Bình Thạnh, TP.HCM<br />
            • Tel: 0866428869<br />
            • Website: www.noi.dating<br />
            iu di – Hãy yêu nhau đi!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default CTAOne;
