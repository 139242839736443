
// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import BlockTitle from "./BlockTitle";
// import 'bootstrap-icons/font/bootstrap-icons.css';

// const Pricing = () => {
//   return (
//     <section className="pricing-one" id="pricing" style={{ margin: '0 20px' }}>
//       <BlockTitle textAlign="center" paraText="Bảng" titleText={`Gói phí`} />
//       <div id="pricing-plans">
//         <Row>
//           {/* Gói 1 */}
//           <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
//             <div className="pricing-one__single" style={{ padding: '20px' }}>
//               <div className="pricing-one__inner" style={{ display: 'flex', flexDirection: 'column', height: '650px' }}>
//                 <p>Gói cơ bản</p>
//                 <h3>200.000 VNĐ</h3>
//                 <ul className="list-unstyled pricing-one__list">
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được duyệt hồ sơ tham gia
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được mở chức năng tìm kiếm theo 3 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> "Quan Tâm" tối đa 3 lượt hồ sơ bất kỳ trong vòng 24h
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình trong vòng 24h
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó
//                   </li>
//                 </ul>
//                 <a href="#none" className="thm-btn pricing-one__btn" style={{ alignSelf: 'center', marginTop: 'auto' }}>
//                   <span>Chọn gói</span>
//                 </a>
//               </div>
//             </div>
//           </Col>

//           {/* Gói 2 */}
//           <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
//             <div className="pricing-one__single" style={{ padding: '20px' }}>
//               <div className="pricing-one__inner" style={{ display: 'flex', flexDirection: 'column', height: '650px' }}>
//                 <p>Gói tiêu chuẩn</p>
//                 <h3>600.000 VNĐ</h3>
//                 <ul className="list-unstyled pricing-one__list">
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được duyệt hồ sơ tham gia
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được mở chức năng tìm kiếm theo 6 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> "Quan Tâm" tối đa 9 lượt hồ sơ bất kỳ trong vòng 24h
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình trong vòng 24h
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội kết nối
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó
//                   </li>
//                 </ul>
//                 <a href="#none" className="thm-btn pricing-one__btn" style={{ alignSelf: 'center', marginTop: 'auto' }}>
//                   <span>Chọn gói</span>
//                 </a>
//               </div>
//             </div>
//           </Col>

//           {/* Gói 3 */}
//           <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
//             <div className="pricing-one__single" style={{ padding: '20px' }}>
//               <div className="pricing-one__inner" style={{ display: 'flex', flexDirection: 'column', height: '650px' }}>
//                 <p>Gói ưu tiên</p>
//                 <h3>3.000.000 VNĐ</h3>
//                 <ul className="list-unstyled pricing-one__list">
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được duyệt hồ sơ tham gia
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được mở chức năng tìm kiếm theo 9 tiêu chí: Nơi ở, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ, chiều cao, quê quán, nghề nghiệp
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> "Quan Tâm" không giới hạn số lượt hồ sơ trong vòng 24h
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật"
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Được quyền "Ẩn Hồ Sơ" của mình
//                   </li>
//                 </ul>
//                 <a href="#none" className="thm-btn pricing-one__btn" style={{ alignSelf: 'center', marginTop: 'auto' }}>
//                   <span>Chọn gói</span>
//                 </a>
//               </div>
//             </div>
//           </Col>

//           {/* Gói 4 */}
//           <Col lg={3} md={6} sm={12} xs={12} className="mb-4">
//             <div className="pricing-one__single" style={{ padding: '20px' }}>
//               <div className="pricing-one__inner" style={{ display: 'flex', flexDirection: 'column', height: '650px' }}>
//                 <p>Gói đặc biệt</p>
//                 <h3>9.000.000 VNĐ</h3>
//                 <ul className="list-unstyled pricing-one__list">
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Nhận được một số quyền lợi ĐẶC BIỆT; Được BQT trực tiếp hỗ trợ kết nối
//                   </li>
//                   <li style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
//                     <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> Chi tiết vui lòng liên hệ BQT để nhận tư vấn
//                   </li>
//                 </ul>
//                 <a href="#none" className="thm-btn pricing-one__btn" style={{ alignSelf: 'center', marginTop: 'auto' }}>
//                   <span>Chọn gói</span>
//                 </a>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </section>
//   );
// };

// export default Pricing;
import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import 'bootstrap-icons/font/bootstrap-icons.css';
import qrCodeImage from '../assets/images/Qrnoi.png';

const Pricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});

  // Thông tin gói phí
  const packages = [
    {
      name: 'Gói cơ bản',
      price: '200.000 VNĐ',
      features: [
        'Được duyệt hồ sơ tham gia',
        'Được mở chức năng tìm kiếm theo 3 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân',
        '"Quan Tâm" tối đa 3 lượt hồ sơ bất kỳ trong vòng 24h',
        'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình trong vòng 24h',
        'Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó'
      ]
    },
    {
      name: 'Gói cao cấp',
      price: '600.000 VNĐ',
      features: [
        'Được duyệt hồ sơ tham gia',
        'Được mở chức năng tìm kiếm theo 6 tiêu chí: Nơi ở, giới tính, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ',
        '"Quan Tâm" tối đa 9 lượt hồ sơ bất kỳ trong vòng 24h',
        'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình trong vòng 24h',
        'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật" giúp tăng cơ hội kết nối',
        'Nếu nâng cấp lên gói khác thì đóng phí 100% gói mới đó'
      ]
    },
    {
      name: 'Gói ưu tiên',
      price: '3.000.000 VNĐ',
      features: [
        'Được duyệt hồ sơ tham gia',
        'Được mở chức năng tìm kiếm theo 9 tiêu chí: Nơi ở, tình trạng hôn nhân, năm sinh, tôn giáo, trình độ, chiều cao, quê quán, nghề nghiệp',
        '"Quan Tâm" không giới hạn số lượt hồ sơ trong vòng 24h',
        'Không giới hạn số lượt được người khác "Quan Tâm" hồ sơ mình',
        'Được xuất hiện trong danh mục "Hồ Sơ Nổi Bật"',
        'Được quyền "Ẩn Hồ Sơ" của mình'
      ]
    },
    {
      name: 'Gói đặc biệt',
      price: '9.000.000 VNĐ',
      features: [
        'Nhận được một số quyền lợi ĐẶC BIỆT; Được BQT trực tiếp hỗ trợ kết nối',
        'Chi tiết vui lòng liên hệ BQT để nhận tư vấn'
      ]
    }
  ];

  // Hiển thị modal với thông tin gói đã chọn
  const handleSelectPackage = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };

  return (
    <section className="pricing-one" id="pricing" style={{ margin: '0 20px' }}>
      <BlockTitle textAlign="center" paraText="Bảng" titleText={`Gói phí`} />
      <div id="pricing-plans">
        <Row>
          {packages.map((pkg, index) => (
            <Col key={index} lg={3} md={6} sm={12} xs={12} className="mb-4">
              <div className="pricing-one__single" style={{ padding: '20px' }}>
                <div className="pricing-one__inner" style={{ display: 'flex', flexDirection: 'column', height: '750px' }}>
                  <p>{pkg.name}</p>
                  <h3>{pkg.price}</h3>
                  <ul className="list-unstyled pricing-one__list">
                    {pkg.features.map((feature, i) => (
                      <li key={i} style={{ textAlign: 'left', position: 'relative', paddingLeft: '30px', lineHeight: '1.6' }}>
                        <i className="bi bi-check" style={{ fontSize: '1.2em', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></i> {feature}
                      </li>
                    ))}
                  </ul>
                  <a 
                    href="#none" 
                    className="thm-btn pricing-one__btn" 
                    style={{ alignSelf: 'center', marginTop: 'auto' }} 
                    onClick={() => handleSelectPackage(pkg)}
                  >
                    <span>Chọn gói</span>
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      {/* Modal thông tin thanh toán */}
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered  >
        <Modal.Header closeButton>
          <Modal.Title>Thông tin chuyển khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Gói đã chọn:</strong> {selectedPackage.name}</p>
          <p><strong>Giá:</strong> {selectedPackage.price}</p>
          <hr />
          <p><span style={{ fontWeight: "bold" }}>Ngân hàng Quân Đội MB Bank</span></p>
          <p>Số TK: <span style={{ fontWeight: "bold" }}>123996699</span></p>
          <p>Tên TK: <span style={{ fontWeight: "bold" }}>Nối Group</span></p>
          <p>Nội dung ghi: <span style={{ fontWeight: "bold" }}>SĐT + CK IUDI APP</span></p>
          <p>Vui lòng bấm liên hệ BQT để chọn đăng ký sử dụng gói trong app</p>
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <img src={qrCodeImage} alt="QR Code" style={{ width: "250px", height: "250px" }} />
          </div>
          <p>(Sau đó chụp hình hóa đơn gửi qua Zalo BQT: 0866428869)</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
        </Modal.Footer>
      </Modal> */}
     <Modal 
  show={showModal} 
  onHide={() => setShowModal(false)} 
  centered 
  dialogClassName="custom-modal"
>
  <Modal.Header closeButton>
    <Modal.Title>Thông tin chuyển khoản</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <p style={{ marginBottom: '10px' }}><strong>Gói đã chọn:</strong> {selectedPackage.name}</p>
  <p style={{ marginBottom: '10px' }}><strong>Giá:</strong> {selectedPackage.price}</p>
    {/* Điều chỉnh hr */}
    <hr style={{ width: '50%', margin: '20px auto', border: '1px solid #ccc' }} />
  <p style={{ marginBottom: '10px' }}><span style={{ fontWeight: "bold" }}>Ngân hàng Quân Đội MB Bank</span></p>
  <p style={{ marginBottom: '10px' }}>Số TK: <span style={{ fontWeight: "bold" }}>123996699</span></p>
  <p style={{ marginBottom: '10px' }}>Tên TK: <span style={{ fontWeight: "bold" }}>Nối Group</span></p>
  <p style={{ marginBottom: '10px' }}>Nội dung ghi: <span style={{ fontWeight: "bold" }}>SĐT + CK IUDI APP</span></p>
  <p style={{ marginBottom: '10px' }}>Vui lòng bấm liên hệ BQT để chọn đăng ký sử dụng gói trong app</p>
  <div style={{ textAlign: 'center', margin: '15px 0' }}>
    <img src={qrCodeImage} alt="QR Code" style={{ width: "200px", height: "200px" }} />
  </div>
  <p style={{ marginBottom: '10px' }}>(Sau đó chụp hình hóa đơn gửi qua Zalo BQT: 0866428869)</p>
</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
  </Modal.Footer>
</Modal>

<style>
  {`
    .custom-modal .modal-dialog {
      max-width: 800px; /* Điều chỉnh độ rộng của modal */
      margin: auto; /* Đảm bảo căn giữa */
    }
  `}
</style>
    </section>
  );
};

export default Pricing;
